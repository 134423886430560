import "../scss/app.scss";

// Polyfill all the things...
import "whatwg-fetch";
// For anything not-DOM (eg String.repeat, Promise, Array.fill etc) use core-js.
// See available options here -> https://github.com/zloirock/core-js#features
import "core-js/es/array/from";
import "core-js/features/promise";

// We manually include the DOM polyfills we believe are necesary.
import applyDomPolyfills from "./includes/dompolyfills";

applyDomPolyfills();

// Production code goes after here
import sentryInit from "../../global/javascript/sentry"
import pressAccordion from "./includes/accordion.js";
import menuActions from "./includes/menuActions";
import swiper from "./includes/swiper";
import youtubeEmbed from "./includes/youtubeEmbed";
import vimeoEmbed from "./includes/vimeoEmbed";
import searchForm from "./includes/searchForm";
import yearDropdown from "./includes/yearDropdown.js";
import locationChanger from "./includes/locationChanger";
import { AjaxForm } from "@neonjungle/birdseed/forms/ajax";

async function smoothScroll() {
	if (!("scrollBehavior" in document.documentElement.style)) {
		// farkin safari
		await import("scroll-behavior-polyfill");
	}
	document.documentElement.style.scrollBehavior = "smooth";
}
smoothScroll();

document.addEventListener("DOMContentLoaded", function () {
	if (window.ENV == "PRODUCTION") {
		sentryInit()
	}
	searchForm();
	menuActions();
	swiper();
	youtubeEmbed();
	vimeoEmbed();
	yearDropdown();
	locationChanger();
	pressAccordion();
	

	let subscribeForm = document.querySelector("[data-signup-form]");
	if (subscribeForm) {
		new AjaxForm(subscribeForm);
	}
});
